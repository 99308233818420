import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { List } from 'immutable';
import { CategoryRecord, ThemeRecord, TopicRecord } from '../models';
// import { FAKE_CATEGOIRES, FAKE_THEMES } from '../helpers';
import ezyLogo from '../images/logo-ezy.png';

interface CategoriesTabProps {
  categories?: List<CategoryRecord> | null;
  themes?: List<ThemeRecord> | null;
  topics?: List<TopicRecord> | null;
}

const CategoriesTab: FunctionComponent<CategoriesTabProps> = ({
  categories,
  themes,
  topics,
}: CategoriesTabProps) => {
  const { pathname } = useLocation();
  const isSTore = pathname.split('/')[1] === 'store';
  const isMagazine = pathname.split('/')[1] === 'magazine';
  const menuRef = useRef(null);

  const [menuScrollLeft, setMenuScrollLeft] = useState(0);
  const [showGuichetStore, setShowGuichetStore] = useState(true);

  useEffect(() => {
    menuRef.current.addEventListener('scroll', e => {
      setShowGuichetStore(menuScrollLeft >= e.target.scrollLeft);
      setMenuScrollLeft(e.target.scrollLeft);
    });
  }, [menuScrollLeft]);

  return (
    <div className="header-bottom">
      <menu className="main-menu d-flex justify-content-center">
        <ul ref={menuRef}>
          {!isSTore && isMagazine && (
            <>
              <li className="main-menu--item">
                <NavLink exact to="/magazine">
                  Accueil
                </NavLink>
              </li>
              {topics?.map(topic => (
                <li className="main-menu--item" key={topic.id}>
                  <NavLink exact to={`/${topic.slug}`}>
                    {topic.title}
                  </NavLink>
                </li>
              ))}
            </>
          )}
          {!isSTore && !isMagazine && (
            <>
              <li className="main-menu--item">
                <NavLink exact to="/" activeClassName="active" replace>
                  Accueil
                </NavLink>
              </li>
              {/* <li className="main-menu--item">
                <NavLink
                  exact
                  to="/producteur/gnaoua-festival-tour-2022"
                  activeClassName="active"
                  replace
                >
                  GNAOUA Festival
                </NavLink>
              </li>
              <li className="main-menu--item">
                <NavLink
                  exact
                  to="/producteur/jazzablanca-festival-2022"
                  activeClassName="active"
                  replace
                >
                  JAZZABLANCA Festival
                </NavLink>
              </li> */}
              {categories?.map(category => (
                <li
                  className="main-menu--item"
                  key={`categories-item-${category.id}`}
                >
                  <NavLink
                    exact
                    to={`/${category.slug}`}
                    activeClassName="active"
                    replace
                  >
                    {category.title}
                  </NavLink>
                </li>
              ))}
              {/* {categories?.map(category => (
                <li
                  className="main-menu--item"
                  key={`categories-item-${category.get('id')}`}
                >
                  <NavLink
                    exact
                    to={`/${category.get('slug')}`}
                    activeClassName="active"
                    replace
                  >
                    {category.get('title')}
                  </NavLink>
                </li>
              ))} */}
            </>
          )}
          {isSTore && !isMagazine && (
            <>
              <li className="main-menu--item">
                <NavLink exact to="/store">
                  Accueil
                </NavLink>
              </li>
              {themes?.map(theme => (
                <li className="main-menu--item" key={`themes-item-${theme.id}`}>
                  <NavLink
                    to={`/store/${theme.slug}`}
                    activeClassName="active"
                    replace
                  >
                    {theme.title}
                  </NavLink>
                </li>
              ))}
              {/* {themes
                ?.map(theme => (
                  <li
                    className="main-menu--item"
                    key={`themes-item-${theme.get('id')}`}
                  >
                    <NavLink
                      to={`/store/${theme.get('slug')}`}
                      activeClassName="active"
                      replace
                    >
                      {theme.get('title')}
                    </NavLink>
                  </li>
                ))
                .filter((_, index) => index !== 0)} */}
            </>
          )}

          <li className="main-menu--item">
            <Link to="/concerts-festivals/ouenza-x-stormy">
              <img src={ezyLogo} height={30} />
            </Link>
          </li>

          {isSTore || isMagazine ? (
            <li className={`main-menu--item highlighted ${showGuichetStore}`}>
              <Link replace to="/">
                <span>Guichet</span> Billetterie
              </Link>
            </li>
          ) : (
            <li className={`main-menu--item highlighted ${showGuichetStore}`}>
              <Link replace to="/store">
                <span>Guichet</span> Store
              </Link>
            </li>
          )}
        </ul>
      </menu>
      {/* <h5
        style={{
          paddingRight: 80,
          paddingLeft: 80,
          paddingTop: 30,
          paddingBottom: 30,
          textAlign: 'center',
          backgroundColor: '#ffd11a',
        }}
      >
        Suite à la forte demande que connaît notre site actuellement nous vous
        invitons à retourner dans quelques instants Merci pour votre
        compréhension et votre confiance
        <br />
        <br />
        بعد الطلب القوي الذي يواجهه موقعنا حاليًا ، ندعوك للعودة بعد لحظات قليلة
        شكرا لتفهمك وثقتك
      </h5> */}
    </div>
  );
};

export default CategoriesTab;
