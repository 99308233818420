import { Record } from 'immutable';
import {
  EventForRec,
  EventsListingForRec,
  EventResponseForRec,
} from '../types';

/**
 * Event record class
 *
 * @export
 * @class EventRecord
 * @extends {Record<EventForRec>()}
 */
export class EventRecord extends Record<EventForRec>(
  {
    id: 0,
    title: null,
    price: null,
    oldPrice: null,
    slug: null,
    description: null,
    summary: null,
    discount: null,
    soldOut: false,
    expiredAt: null,
    openingTime: null,
    closingDoorsAt: null,
    createdAt: null,
    slide: null,
    cover: null,
    customField: null,
    category: null,
    place: null,
    isPlan: false,
    provider: null,
    images: null,
    offers: null,
    similarEvents: null,
    seatsChartId: null,
    metaTitle: null,
    metaDescription: null,
    metaKeywords: null,
    status: null,
    sticker: null,
    isSubscription: null,
    link: null,
    fbPixelId: null,
    gtmId: null,
    paymentMethods: null,
    isPrivate: null,
    token: null,
    tiktokId: null,
  },
  'event',
) {}

/**
 * Events list record
 *
 * @export
 * @class EventsListRecord
 * @extends {Record<EventsListingForRec>()}
 */
export class EventsListRecord extends Record<EventsListingForRec>(
  {
    list: null,
    success: false,
    pagination: null,
    provider: null,
    loadMore: false,
  },
  'events-list',
) {}

/**
 * Event details record details response
 *
 * @export
 * @class EventResponseRecord
 * @extends {Record<EventResponseForRec>()}
 */
export class EventResponseRecord extends Record<EventResponseForRec>(
  {
    success: false,
    event: null,
  },
  'event-response',
) {}
